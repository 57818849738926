<template>
    <div class="pt-10">
        <b-field grouped>
            <b-input placeholder="Subject Name" class="w-80" v-model="NewSubject"></b-input>
            <p class="control">
                <b-button class="button is-primary" icon-pack='far'
                    icon-left="plus" @click="addsubject">Add Subject</b-button>
            </p>
        </b-field>
        
        <div class="mt-16 height overflow-y-scroll no-scrollbar">
            <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50 table-fixed">
                <tr>
                    <th scope="col" class="pl-16 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Index
                    </th>
                    <th scope="col" class="pl-16 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subject
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Delete</span>
                    </th>
                </tr>
                </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(subject,index) in subjects" :key="subject.id">
                  <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ index + 1 }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ subject.name }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button href="#" class="text-red-600 hover:text-red-900" @click="deletesubject(subject.id)">
                     <i class="far h fa-trash-alt pr-2"/> Delete</button>
                </td>
              </tr>
            </tbody>
            </table>
        </div>


     

    </div>
</template>

<script>
import * as fb from '../firebase'
import {mapState} from 'vuex'
    export default {
        name:'Subjects',
        data() {
            return {
                NewSubject:''
            }
        },
        computed:{
          ...mapState(['subjects'])
        },
        methods: {
          async addsubject(){
            if (this.NewSubject != '') {
               await fb.subjectCollection.doc().set({
                   name: this.NewSubject,
                });
              
            }
            this.NewSubject = ''
          },
          async deletesubject(id){
            await fb.subjectCollection.doc(id).delete();
          }
        },
    }
</script>
